import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  createFilterOptions,
  FilterOptionsState,
  TextField,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { ComboboxProps, Values } from '@elements/forms/fields/combobox/index'
import { FormError } from '@elements/forms/fields/error'
import { classNames } from '@utils/helpers/classNameHelper'

export const ComboboxV2 = <T,>({
  name,
  label,
  control,
  hasReset = false,
  allowCustom = false,
  errorDisplayType = 'text',
  ...props
}: ComboboxProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined,
    rules: { ...props.rules, required: true },
    shouldUnregister: true,
  })

  const errorRef = useRef<HTMLParagraphElement>(null)
  const [paddingHeight, setPaddingHeight] = useState('20px')
  const [searchable, setSearchable] = useState(false)

  useEffect(() => {
    setPaddingHeight(errorRef.current?.scrollHeight ? `${errorRef.current?.scrollHeight + 16}px` : '')
  }, [errorRef.current, error])

  // find out if this component is displaying remote search-based results, so it doesn't do further filtering
  useEffect(() => {
    if (props.searching) setSearchable(true)
  }, [props.searching])

  const filterOptions = (options: Values<T>[], input: FilterOptionsState<Values<T>>) => {
    const filter = createFilterOptions({ limit: 50, stringify: (v: Values<T>) => v.name })
    let filtered = filter ? filter(options, input) : []

    const { inputValue } = input
    const isExisting = options.some((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()))
    if (props.invalidSearch) {
      return [{ name: `${props.invalidSearchMessage}`, value: null as T }]
    }

    // Suggest the creation of a new value
    if (allowCustom && inputValue !== '' && !isExisting) {
      return [
        {
          name: `Add "${inputValue}"`,
          value: { name: inputValue, value: {} } as T,
        },
      ]
    }
    return searchable ? options : filtered
  }

  const defaultOnInputChange = (_: unknown) => {}

  const getInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} error={!!error} inputRef={props.inputRef} label={label} required />
  }

  const getOptions = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Values<T>) => {
    return (
      <li {...optionProps} key={optionProps.id}>
        {(props.displayValue && props.displayValue(option.value)) || option.name}
      </li>
    )
  }

  const onChange = (
    syntheticEvent: React.SyntheticEvent<Element, Event>,
    value: NonNullable<string | Values<T>> | null,
    reason: AutocompleteChangeReason
  ) => {
    field.onChange((value as Values<T>)?.value || undefined)
    if (reason === 'clear' && props.reset) props.reset()
  }

  const onInputChange = (e: React.SyntheticEvent<Element, Event>) => {
    props.onChange ? props.onChange(e as React.ChangeEvent<HTMLInputElement>) : defaultOnInputChange(e)
  }

  return (
    <>
      <Autocomplete<Values<T>, false, boolean, boolean>
        className={classNames('relative', props?.className)}
        style={{
          paddingBottom: !!error ? paddingHeight : '',
        }}
        id={`combo-${name}`}
        data-testid={`combobox-input-${name}`}
        selectOnFocus
        handleHomeEndKeys
        filterSelectedOptions={true}
        freeSolo={allowCustom}
        options={props.values}
        filterOptions={filterOptions}
        disableClearable={!hasReset}
        getOptionLabel={(o) => (typeof o === 'string' ? o : o.name)}
        getOptionDisabled={() => !!props.invalidSearch}
        loading={props.searching}
        loadingText="Searching..."
        onInputChange={onInputChange}
        onChange={onChange}
        renderOption={getOptions}
        renderInput={getInput}
        isOptionEqualToValue={(option: Values<T>, value) => option.name === value.name}
      />
      <FormError error={error} errorDisplayType={errorDisplayType} />
    </>
  )
}
