import React from 'react'
import { UseControllerProps } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types'
import { ErrorDisplayType } from '@elements/forms/fields/error'
import { ListboxV3 } from '@elements/forms/fields/listbox/listbox-v3'

export interface Values {
  name: string
  value: string
}

export interface ListboxProps extends UseControllerProps<Values & any> {
  label: string
  control: Control<any>
  floatingLabel?: boolean
  tooltip?: JSX.Element | null
  shrinkIfNoError?: boolean
  defaultEmpty?: boolean
  required?: boolean
  hasRequiredLabel?: boolean
  values: Values[]
  className?: string
  disabled?: boolean
  placeholder?: string
  size?: 'sm' | 'md' | 'lg'
  errorDisplayType?: ErrorDisplayType
}

export const DEFAULT_LISTBOX = 'Select an option...'

export const Listbox = ({ ...props }: ListboxProps) => {
  return <ListboxV3 {...props} />
}
