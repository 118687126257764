import React, { Ref } from 'react'
import { UseControllerProps } from 'react-hook-form'
import { ComboboxV2 } from '@elements/forms/fields/combobox/combobox-v2'
import { ErrorDisplayType } from '@elements/forms/fields/error'

export interface Values<T = string> {
  name: string
  value: T
}

export interface ComboboxProps<T> extends UseControllerProps<any> {
  label: string
  floatingLabel?: boolean
  tooltip?: JSX.Element | null
  searching?: boolean
  shrinkIfNoError?: boolean
  invalidSearch?: boolean
  invalidSearchMessage?: string
  reset?: () => void
  hasReset?: boolean
  allowCustom?: boolean
  values: Values<T>[]
  className?: string
  inputRef?: Ref<HTMLInputElement>
  displayValue?(item: any): string
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void
  errorDisplayType?: ErrorDisplayType
}

export const Combobox = <T,>({ ...props }: ComboboxProps<T>) => {
  return <ComboboxV2 {...props} />
}
