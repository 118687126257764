import { DomainAddress } from '@home-in/models'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Values } from '@elements/forms/fields/combobox'
import { ComboboxV1 } from '@elements/forms/fields/combobox/combobox-v1'
import { ComboboxV2 } from '@elements/forms/fields/combobox/combobox-v2'
import { useLazySuggestPropertiesQuery } from '@redux/apis/domain'
import { ErrorDisplayType } from './error'
import { ControlledFieldProps } from './fields.interface'

type AddressSearchFieldComponentProps = ControlledFieldProps & {
  debounce?: number
  errorDisplayType?: ErrorDisplayType
}

type AddressSearchFieldComponentPropsWithVersion = AddressSearchFieldComponentProps & { version: 'v1' | 'v2' }

export const AddressSearchField = ({ ...props }: AddressSearchFieldComponentProps) => {
  return <AddressSearchFieldV2 {...props} />
}

export const AddressSearchFieldV2 = ({ debounce = 800, ...props }: AddressSearchFieldComponentProps) => {
  return <AddressSearchFieldComponent {...props} version={'v2'} debounce={debounce} />
}

const AddressSearchFieldComponent = ({
  name,
  label,
  control,
  version,
  ...props
}: AddressSearchFieldComponentPropsWithVersion) => {
  const [properties, setProperties] = useState<Values<DomainAddress>[]>([])
  const [lastSearch, setLastSearch] = useState('')
  const [address, setAddress] = useState('')
  const [searching, setSearching] = useState(false)

  const [trigger, { data }] = useLazySuggestPropertiesQuery()

  const doPropertySearch = useDebouncedCallback(async (address: string): Promise<void> => {
    await trigger({ terms: address, time: new Date().toString() })
  }, props.debounce)

  useEffect(() => {
    if (!Array.isArray(data) || data.length === 0) return

    const formattedProperties: Values<DomainAddress>[] =
      data
        ?.filter((address) => (address?.relativeScore || 0) >= 60)
        .map(({ address, addressComponents, id }) => {
          const tidyAddress = address?.replace(' , ', ', ').trim() || ''
          return { name: tidyAddress, value: { id, address: tidyAddress, addressComponents } }
        }) || []

    setProperties(formattedProperties)
    setLastSearch(address)
    setSearching(false)
  }, [data])

  const canSearch = (address: string) => {
    return /[\w\/'’‘ ]{3,}[^ ]$/.test(address)
  }

  useEffect(() => {
    if (address === lastSearch || address === '' || !canSearch(address)) {
      return
    }
    setLastSearch(address)
    setSearching(true)
    doPropertySearch(address)
  }, [address, lastSearch])

  const Component = version === 'v1' ? ComboboxV1 : ComboboxV2

  return (
    <>
      <Component<DomainAddress>
        {...props}
        values={!searching ? properties : []}
        invalidSearch={(address !== lastSearch || address === '') && !properties.length}
        invalidSearchMessage={'Search for your address with your unit/street number and your street name'}
        searching={searching}
        name={name}
        label={label as string}
        onChange={(event) => {
          if (event == null) return
          setAddress(event.target.value)
          props?.onChange?.(event)
        }}
        onFocus={(event) => {
          if (event == null) return
          setAddress(event.target.value)
        }}
        control={control}
        displayValue={(value) => value?.address || ''}
        hasReset={true}
        inputRef={props?.innerRef}
        reset={() => {
          setAddress('')
          setLastSearch('')
          setProperties([])
        }}
      />
    </>
  )
}
