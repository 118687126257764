import React from 'react'
import { useController, Control, UseControllerProps } from 'react-hook-form'
import { FormErrorNormalCSS } from '@elements/forms/form-error'
import { LabelRequired } from '@elements/forms/label-required'
import { classNames } from '@utils/helpers/classNameHelper'
import { REQUIED_RADIO_BUTTON_MESSAGE_VARIATION } from './constants'

export interface RadioCardInput {
  input: string
  icon?: React.ComponentType<{ sx?: object }>
}

export interface RadioCardsProps extends UseControllerProps<any> {
  name: string
  label?: string
  control: Control<any>
  disabled?: boolean
  inputs: RadioCardInput[]
  description?: string
  className?: string
  labelClassName?: string
}

export const RadioCards = ({
  name,
  label,
  control,
  disabled = false,
  inputs,
  className,
  defaultValue,
  description,
  labelClassName = '',
}: RadioCardsProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules: { required: REQUIED_RADIO_BUTTON_MESSAGE_VARIATION } })

  return (
    <div>
      <div className={classNames('flex flex-col group relative my-4', { 'pb-8': !!error?.message }, className)}>
        {label && (
          <div className="flex">
            <p id={`${name}-label`} className={classNames('text-xl', labelClassName)}>
              <LabelRequired>{label}</LabelRequired>
            </p>
          </div>
        )}

        {description && <p className={'-mt-1 text-xs text-muted sm:text-sm'}>{description}</p>}

        {inputs
          .filter(({ input }) => !!input)
          .map((inputObj: RadioCardInput) => {
            const { input, icon: Icon } = inputObj

            return (
              <label
                key={input}
                className={classNames(
                  'block inline-flex border rounded border-black/[0.12] items-center my-1 p-4',
                  'hover:bg-primary-dark/[0.07]',
                  'has-[:checked]:border-primary-dark has-[:checked]:bg-primary-dark/[0.07]'
                )}
              >
                {Icon && (
                  <div className="pr-4">
                    <Icon sx={{ color: 'rgba(0, 0, 0, 0.33)' }} />
                  </div>
                )}{' '}
                {input}
                <input
                  {...field}
                  type="radio"
                  value={input}
                  required
                  className="opacity-0 checked:border-3 checked:border-solid checked:text-black"
                  aria-labelledby={`${name}-label`}
                  data-testid={`radio-${name}-${input}`}
                  disabled={disabled}
                  checked={field.value === input || defaultValue === input}
                />
              </label>
            )
          })}
      </div>
      <div>
        <FormErrorNormalCSS condition={!!error} message={error?.message || ''} />
      </div>
    </div>
  )
}
