import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DateTime } from 'luxon'
import React from 'react'
import { useController } from 'react-hook-form'
import { REQUIRED_MESSAGE } from '@elements/forms/constants'
import { classNames } from '@utils/helpers/classNameHelper'
import { DateTimeProps } from '.'

const defaultFormat = 'dd/MM/yyyy HH:mm'
const inputFormat = 'yyyy-MM-dd'

export const DateTimeFieldV2 = ({
  name,
  control,
  label,
  required,
  rules,
  onClick,
  min,
  max,
  format: propFormat,
  className,
  requiredErrorMessage,
}: DateTimeProps) => {
  const mergedRules = {
    ...rules,
    ...(required && {
      required: requiredErrorMessage ? requiredErrorMessage : REQUIRED_MESSAGE,
    }),
  }

  const {
    field,
    fieldState: { error: formError },
  } = useController({ name, rules: mergedRules, defaultValue: null, control, shouldUnregister: true })

  const format = propFormat?.length ? propFormat : defaultFormat
  const minDate = min && DateTime.fromFormat(min, inputFormat)
  const maxDate = max && DateTime.fromFormat(max, inputFormat)
  const hasTime = format.includes('H')
  const Picker = hasTime ? DateTimePicker : DatePicker

  return (
    <div className={classNames(className)}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Picker
          {...field}
          onChange={(value) =>
            field.onChange((value as DateTime)?.toFormat(hasTime ? "yyyy-MM-dd'T'HH:mm" : 'yyyy-MM-dd'))
          }
          value={field.value ? DateTime.fromFormat(field.value, format) : null}
          format={format}
          name={name}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          aria-labelledby={`${name}-date-label`}
          onOpen={() => typeof onClick === 'function' && onClick()}
          slotProps={{
            actionBar: hasTime ? { actions: ['accept', 'clear'] } : {},
            textField: {
              error: !!formError,
              required: required,
              helperText: formError?.message,
            },
          }}
          className="w-full"
        />
      </LocalizationProvider>
    </div>
  )
}
