import React from 'react'
import { ControlledFieldProps } from '../fields.interface'
import { DateTimeFieldV2 } from './datetime-field-v2'

interface Props {
  min?: string
  max?: string
  format?: string
  requiredErrorMessage?: string
}

export type DateTimeProps = ControlledFieldProps & Props

export const DateTimeField = (props: DateTimeProps) => {
  return <DateTimeFieldV2 {...props} />
}
