import React from 'react'
import { Control, UseControllerProps } from 'react-hook-form'
import { RadioButtonsV2 } from './radio-buttons.v2'

export enum OptionWidthEnum {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export type OptionWidth = keyof typeof OptionWidthEnum

export interface RadioButtonProps extends UseControllerProps<any> {
  name: string
  label?: string
  control: Control<any>
  disabled?: boolean
  inputs: string[] | object
  tooltip?: JSX.Element | null
  description?: string
  width?: OptionWidth
  className?: string
  labelClassName?: string
}

export const RadioButtons = (props: RadioButtonProps) => {
  return <RadioButtonsV2 {...props} />
}
