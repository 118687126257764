export const EMAIL_INVALID_MESSAGE = 'Please enter a valid email'
export const TEL_REGEX = /^[+]?\d{8,11}$/
export const MOBILE_TEL_REGEX = /^(\+614|04)\d{8}$/
export const TEL_INVALID_MESSAGE = 'Please enter a valid phone number'
export const MOBILE_TEL_INVALID_MESSAGE = 'Please enter a valid mobile number'
export const NAME_REGEX = /^[\p{L}\-'@.`,() ]+$/u
export const NAME_INVALID_MESSAGE = 'Please enter a valid name'
export const FULL_NAME_REGEX = /^\p{L}+[-'@.`,()]?\p{L}+(?: \p{L}+[-'@.`,()]?\p{L}+)+$/u
export const FULL_NAME_INVALID_MESSAGE = 'Please enter at least two valid names'
export const REQUIRED_MESSAGE = 'This field cannot be left blank'
export const REQUIRED_RADIO_BUTTON_MESSAGE = 'Please choose an option'
export const REQUIED_RADIO_BUTTON_MESSAGE_VARIATION = 'Please make a selection before getting started'

export const PASSWORD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
export const PASSWORD_INVALID_MESSAGE =
  'Please include at least 8 of special characters, upper & lower case letters, and numbers'
export const UNMATCHED_PASSWORD_MESSAGE = 'Passwords mismatch'
export const TOTAL_SHARE_INVALID = 'Total share should equal 100%'

export const BSB_REGEX = /^\d{6}$/
export const BSB_INVALID_MESSAGE = 'The BSB is a 6 digit number.'

// 100 MB
export const MAX_FILE_SIZE = 100000000
